import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentContainer from "../components/contentContainer"
import TwoColumnComponent from "../components/twoColumnComponent"

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {
            slug: {
                eq: $slug
            }
        }) {
            html
            fileAbsolutePath
            frontmatter {
                title
                subtitle
                date
                seoTitle
                seoDescription
            }
        }
    }
`



const TwoColumnPost = (props) => {
    const post = props.data.markdownRemark
    const siteTitle = "post"
    return (
        <Layout location={props.location} title={siteTitle}>
        <SEO  title={post.frontmatter.seoTitle || post.frontmatter.title} description={post.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer paddingMobile paddingTop>
            <TwoColumnComponent title={post.frontmatter.title} titleContent={post.frontmatter.subtitle} 
                                backbutton={false}>
                          <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
            </TwoColumnComponent>
          </ContentContainer>
        </div>
      </Layout>
      )
}

export default TwoColumnPost
